import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'

import './style.css';

import 'react-perfect-scrollbar/dist/css/styles.css';
const menus = [
    {
        id: 1,
        title: 'Home',
        id_section: "home",
    },
    {
        id: 2,
        title: 'Sobre',
        id_section: "sobre",
    },
    {
        id: 3,
        title: 'Áreas de prática',
        id_section: "area-de-pratica",
    },
  
    {
        id: 4,
        title: 'Contato',
        id_section: "contato",
    }, 
]


class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = (id_section) => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })

        switch (id_section) {
            case "home":
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
            case "sobre":
                let targetElement = document.getElementById(id_section);
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
            case "sobre":
                let targetElement2 = document.getElementById(id_section);
                window.scrollTo({
                    top: targetElement2.offsetTop,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
            case "area-de-pratica":
                let targetElement3 = document.getElementById(id_section);
                window.scrollTo({
                    top: targetElement3.offsetTop,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
            case "portifolio":
                let targetElement4 = document.getElementById(id_section);
                window.scrollTo({
                    top: targetElement4.offsetTop,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
            case "contato":
                let targetElement5 = document.getElementById(id_section);
                window.scrollTo({
                    top: targetElement5.offsetTop,
                    behavior: 'smooth', // opcional, para animação suave
                });
                break;
        
            default:
                break;
        }
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <PerfectScrollbar >
                    <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                        {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

                        <ul className="responsivemenu">
                            {menus.map(item => {
                                return (
                                    <li key={item.id}>
                                        <Link onClick={() => {
                                            this.menuHandler(item.id_section)
                                        }} >{item.title}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    </PerfectScrollbar>
                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}

export default MobileMenu;