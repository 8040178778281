import React, { Component } from 'react';
import axios from 'axios';

class ContactForm extends Component {

    state = {
        name: '',
        send_success: false,
        button: 'Enviar',
        email: '',
        number: '',
        events: '',
        notes: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = async (e) => {
        e.preventDefault();

        const { 
            name,
            email,
            number,
            notes, 
            error 
        } = this.state;

        if (name === '') {
            error.name = "Nome obrigatório.";
        }
        if (email === '') {
            error.email = "E-mail obrigatório.";
        }
        if (number === '') {
            error.number = "Telefone obrigatório.";
        }
        if (notes === '') {
            error.notes = "Mensagem obrigatório.";
        }


        if (error) {
            this.setState({
                error
            })
        }

        this.setState({
            button: 'Enviando...'
        })

        await axios.post('https://adv-api.vercel.app/send-email', { text: `Nome: ${name}\nTelefone: ${number}\nE-mail: ${email}\nMensagem: ${notes}` });

        this.setState({
            send_success: true,
            button: 'Enviar'
        })
        
        if (error.name === '' && error.email === '' && error.email === '' && error.number === '' && error.notes === '') {
            this.setState({
                name: '',
                email: '',
                number: '',
                notes: '',
                error: {}
            })
        }
    }

    render() {

        const { name,
            email,
            number,
            events,
            notes, error } = this.state;

        return (
            <form onSubmit={this.subimtHandler}>
                <div className="contact-form form-style row">
                    <div className="col-12 col-lg-6">
                        <input type="text" value={name} onChange={this.changeHandler} placeholder="Nome*" id="fname" name="name" />
                        <p>{error.name ? error.name : ''}</p>
                    </div>
                    <div className="col col-lg-6">
                        <input type="text" placeholder="Telefone" onChange={this.changeHandler} value={number} id="number" name="number" />
                        <p>{error.number ? error.number : ''}</p>
                    </div>
                    <div className="col-12  col-lg-6">
                        <input type="email" placeholder="E-mail" onChange={this.changeHandler} value={email} id="email" name="email" />
                        <p>{error.email ? error.email : ''}</p>
                    </div>
                  
                    <div className="col-12 col-sm-12">
                        <textarea className="contact-textarea" value={notes} onChange={this.changeHandler} placeholder="Mensagem" name="notes"></textarea>
                        <p>{error.notes ? error.notes : ''}</p>
                        <p style={{color: "green"}}>{this.state.send_success == true ? "enviado com sucesso!" : ''}</p>
                    </div>
          
                    <div className="col-12">
                            <button type="submit" className="theme-btn">{this.state.button}</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default ContactForm;