import React from 'react';
import ContactForm from '../CommentForm'
import './style.css'



const ContactSection = () => {

    return(
        <div className="contact-area section-padding" id="contato">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-text">
                            <h2>Fale conosco</h2>
                            <p>Estamos à disposição para atendê-lo! Utilize o formulário para entrar em contato conosco, seja para esclarecer dúvidas, discutir suas necessidades legais ou iniciar uma parceria. Sua satisfação e confiança são nossa prioridade. Aguardamos sua mensagem!</p>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Endereço</h4>
                                    <span>Atendimento on-line em todo brasil</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Telefone</h4>
                                    <span>11 94770-2457</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Email</h4>
                                    <span>juridico@lqadvocacia.com.br</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-7 col-md-12 col-sm-12">
                        <div className="contact-content">
                            <div className="contact-form"> 
                                    <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ContactSection;
