import React from  'react';
import Navbar3 from '../../components/Navbar3'
import SimpleSlider3 from '../../components/hero3'
import AboutSection from '../../components/about'
import PracticeArea from '../../components/PracticeArea'
import Porfolio from '../../components/case'
import ContactSection from '../../components/ContactSection'
import FooterSection from '../../components/Footer'
import logo from '../../images/logo/wpp.png'


const Homepage3 = () => {
    return(
        <html>
            <head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap" rel="stylesheet" />
            </head>
            <body>
                <Navbar3 />
                <div className="fixed-icon" style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 999 }}>

                    <a href="https://api.whatsapp.com/send?phone=5511947702457&text=Oi,%20vim%20atrav%C3%A9s%20do%20site,%20preciso%20de%20aux%C3%ADlio%20jur%C3%ADdico." target="_blank"><img style={{ width: 45, opacity: 0.7 }} src={logo} alt="" /></a>
                </div>
                <SimpleSlider3 />
                <AboutSection targetSection="sobre" />
                <PracticeArea subclass={'practice-area-3'} />
                <ContactSection />
                <FooterSection /> 
                </body>
        </html>
    )
}

export default Homepage3;