import React from  'react';

// components
import Navbar3 from '../../components/Navbar3'
import Breadcumb from '../../components/breadcumb'

import Contactpage from '../../components/Contactpage'
import FooterSection from '../../components/Footer'


const ContactPage = () => {
    return(
       <div>
           <Navbar3/>
           <Breadcumb bdtitle={'Fale conosco'} bdsub={'Contato'}/>
           <Contactpage/>
           <FooterSection/>
       </div>
    )
}

export default ContactPage;