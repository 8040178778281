import React from 'react';
import AllRoute from '../router'
import './App.css';


const App = () => { 

  return (
    <div className="App">
          <AllRoute/>
    </div>
  );
}

export default App;
