import React, { Component } from "react";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class SimpleSlider3 extends Component {
    render() {
      return (
        <section className="hero hero-slider-wrapper hero-style-3">
            <div className="hero-slider">
                <div className="slide3 slide" > 
                    <div className="container" >
                <div className="row slide-caption">
                  <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

                    <h3 style={{ color: 'white', width: "80Vw", textAlign: "center", fontSize: window.innerWidth > 650 ? 35 : 20 }}>Quintino Advocacia e Consultoria</h3>
                    <p style={{ padding: 0, margin: 0, width: "70Vw", textAlign: "center", height: "auto", fontSize: window.innerWidth > 650 ? 20 : 12, lineHeight: 1 }}>Escritório de advocacia jovem e inovador, especializado nas áreas do direito do trabalho, regulatório e societário. Nossa equipe dinâmica de advogados combina experiência e inovação para oferecer soluções eficazes e personalizadas aos nossos clientes.</p>
                    <div className="btns" style={{ marginTop: 10 }}>
                      <div className="btn-style"><a href="https://api.whatsapp.com/send?phone=5511947702457&text=Oi,%20vim%20atrav%C3%A9s%20do%20site,%20preciso%20de%20aux%C3%ADlio%20jur%C3%ADdico." target="_blank">Fale Conosco</a></div>
                    </div>
                  </div>
     
  
                        </div>
                    </div>
                </div>
        
            </div>
        </section>
      );
    }
  }

export default SimpleSlider3;