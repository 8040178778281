import React from 'react';

import pr1 from '../../images/icon/4.png'
import pr2 from '../../images/icon/5.png'
import pr3 from '../../images/icon/6.png'
import pr4 from '../../images/icon/8.png'
import pr5 from '../../images/icon/9.png'
import pr6 from '../../images/icon/10.png'

import './style.css'

const PracticeArea = ( props ) => {

    return(
        <div className={`practice-area  ptb-100-70 ${props.subclass}`} id="area-de-pratica">
            <div className="container">
                <div className="col-12">
                    <div className="section-title-1 text-center">
                        <span>Áreas de prática</span>
                        <h2>Equipe multidisciplinar, segmentada e preparada, para oferecer alto nível de consultoria e assessoria</h2>
                    </div>
                </div>
                <div className="row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr3} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>Trabalhista</h2>
                                    <p>Explore a excelência em Direito do Trabalho conosco, onde oferecemos assessoria ágil e estratégica. Nosso escritório se distingue pela prestação de suporte legal especializado, abrangendo desde o reconhecimento de vínculo empregatício até questões como hora extra, rescisão indireta, adicional de insalubridade, adicional de periculosidade, desvio e acúmulo de função, dano moral e verbas rescisórias. Com uma abordagem comprometida e experiente.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots dots-2"></div>
                                    </div>
                                    <i className="fi"><img src={pr2} alt="" /></i>
                                </div>
                                <div className="practice-content">
                                    <h2>Consumidor</h2>
                                    <p>Em nosso escritório, comprometemo-nos a proteger seus direitos em transações comerciais, proporcionando aconselhamento especializado em casos de práticas abusivas, publicidade enganosa e defeitos em produtos ou serviços. Buscamos não apenas reparação por danos, mas também soluções eficazes, utilizando nossa experiência para resolver conflitos de forma estratégica.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr5} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>Regulatório</h2>
                                    <p>Dedicamo-nos a oferecer suporte jurídico abrangente em órgãos reguladores como ANVISA, SIVISA, CRF, CETESB, entre outras licenças, fornecendo soluções especializadas para a obtenção e manutenção de alvarás de funcionamento. Nossa equipe capacitada assegura conformidade com normas e regulamentos, tornando o processo de regularização eficiente e sem contratempos. Seja você um empreendimento farmacêutico, alimentício, ou de outra natureza regulamentada.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr4} alt="" /></i>
                                </div>
                                <div className="practice-content">
                                    <h2>Societário</h2>
                                    <p>Oferecemos serviços abrangentes, desde a constituição de sociedades até reestruturações e dissoluções, assegurando conformidade legal e maximizando o potencial de crescimento, reestruturação administrativa, exclusão de sócios, dissoluções societárias, fusão, cisão, incorporação, aquisição, avaliação de responsabilidades contratuais e extracontratuais.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
     )
        
}

export default PracticeArea;
