import React from 'react';
import abimg3 from '../../images/about/img-3.png'

import './style.css'

const AboutSection = () => {

    return(
        <div className="about-style-2" id="sobre">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-m">
                        <div className="about-img">
                            <img src={abimg3} alt=""/>
                       
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Sócia Fundadora</h2>
                            </div>
                            <p style={{padding: 0, margin: 0}}>Larissa Quintino</p>
                            <p style={{padding: 0, margin: 0}}>Advogada. OAB/SP 454.906</p>
                            <p style={{padding: 0, margin: 0}}>Graduada pelo Instituto Brasileiro de Mercados de Capitais (IBMEC), especialista em Direito e Processo do Trabalho pela Pontifícia Universidade Católica de São Paulo (PUC-SP).</p>
                            <div style={{display: 'flex'}}>
                                <a style={{ marginRight: 10 }} target="_blank" href="https://br.linkedin.com/in/larissa-vieira-quintino-8961a8204"><i style={{ color: "#585761"}} className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href='https://www.instagram.com/lq.adv' target="_blank"  ><i style={{ color: "#585761" }}  className="fa fa-instagram" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default AboutSection;
