import React, { useState } from 'react';

const ExpandedTitle = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }} onClick={toggleExpanded}><h5 style={{ display: "flex", justifyContent: "flex-start", width: "170px" }}><i style={{marginRight: "10px"}} className="fa fa-check-square" aria-hidden="true"></i>{title}</h5></div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {isExpanded && <div style={{ width: "350px" }}>{children}</div>}
            </div>
        </div>
    );
};

export default ExpandedTitle;
