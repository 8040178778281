import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import MobileMenu from '../MobileMenu'


const Header3 = (props) => {
    return (
        <header>
            <div className="header-style-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-10 col-sm-10 col-10 col-t">
                            <div className="logo">
                                <Link to="/"><img style={{ width: 75 }} src={logo} alt="" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block col-m">
                            <div className="main-menu">
                                <nav className="nav_mobile_menu">
                                    <ul>
                                        <li style={{ margin: 0, }} className="active"><Link style={{ color: "#353535" }} onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth', // opcional, para animação suave
                                            });

                                        }}>Home</Link></li>
                                        <li style={{ margin: 0 }}><Link style={{ color: "#353535" }} onClick={() => {
                                            const targetElement = document.getElementById("sobre");
                                            window.scrollTo({
                                                top: targetElement.offsetTop,
                                                behavior: 'smooth', // opcional, para animação suave
                                            });

                                        }}>sobre</Link></li>
                                        <li style={{ margin: 0 }}><Link style={{ color: "#353535" }} onClick={() => {
                                            const targetElement = document.getElementById("area-de-pratica");

                                            window.scrollTo({
                                                top: targetElement.offsetTop,
                                                behavior: 'smooth', // opcional, para animação suave
                                            });

                                        }}>Áreas de prática</Link></li>
                                        <li style={{ margin: 0, }}><Link style={{ color: "#353535" }} onClick={() => {
                                            const targetElement = document.getElementById("contato");

                                            window.scrollTo({
                                                top: targetElement.offsetTop,
                                                behavior: 'smooth', // opcional, para animação suave
                                            });

                                        }}>Contato</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-1 col-1 mt-2">
                            <MobileMenu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header3;
