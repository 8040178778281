import React from 'react';
import {Link} from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import './style.css'
import ExpandedTitle from './expanded';

const FooterSection = () => {

    return(
        <div className="footer-area">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-logo">
                                <Link to="/"><img style={{width: 150}} src={logo} alt=""/></Link>
                            </div>
                            <p style={{ color: "#353535", textAlign: "left"}}>O atendimento é 100% personalizado pelos Advogados perante os Clientes, com o melhor custo e a melhor qualidade, ética, transparência, segurança e confiança possíveis.</p>
                        </div>
                         <div className="col-lg-3 col-md-6 col-sm-6">
                         
                        </div>    
                      
                        <div className="col-lg-6 col-md-8 col-sm-8">
                            <div className="footer-link" style={{display: "flex", justifyContent: "flex-end"}}>
                                <h5><i className="fa fa-map-marker" aria-hidden="true" style={{marginRight: 10}}></i>atendimento em todo brasil</h5>
                            </div>
                            <div className="footer-link">
                                <ExpandedTitle title="Nossa Visão">
                                    <p style={{ color: "#353535", textAlign: "left" }}>Almejar o reconhecimento como um escritório de referência nas áreas em que atuamos, estabelecendo-nos como parceiros ininterruptos de nossos clientes ao longo de todas as etapas de seus empreendimentos. Priorizar, de forma substancial, a realização pessoal de cada integrante de nossa equipe.</p>
                                </ExpandedTitle>
                                <ExpandedTitle title="Nossa Missão">
                                    <p style={{ color: "#353535", textAlign: "left" }}>Alcançar esse objetivo por meio de um serviço próximo, custo reduzido, excelência técnica e orientação centrada em resultados.</p>
                                </ExpandedTitle>
                                <ExpandedTitle title="Nossos Valores">
                                    <p style={{ color: "#353535", textAlign: "left" }}>Integridade, garantindo uma conduta franca e leal; eficiência, assegurando entrega ágil e resultados precisos; dinamismo, caracterizado pela
                                        iniciativa proativa e cumprimento de prazos; e proximidade, promovendo uma interação estreita e séria com o cliente, tanto interno quanto externo.</p>
                                </ExpandedTitle>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-9 col-12">
                                <span style={{ color: "#353535" }}> © 2024 Unix tec.</span>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-3 col-12">
                                <ul className="d-flex">
                                    <li><a href='https://www.instagram.com/lq.adv' target="_blank"  ><i style={{ color: "#585761" }} className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a href="https://br.linkedin.com/in/larissa-vieira-quintino-8961a8204" target="_blank"><i style={{ color: "#353535" }}  className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default FooterSection;
